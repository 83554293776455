import requestService from "../requestService";
import store from '@/store';
export default {
    async getStartCities() {
        const response = await requestService.get(`/v1/start_cities`, {
            headers: { 'Accept-Language': store.getters.locale.toLowerCase() }
        })
        return response?.data
    },
    async getNextCities(start_city) {
        const response = await requestService.get(`/v1/next_cities/${start_city}`, {
            headers: { 'Accept-Language': store.getters.locale.toLowerCase() }
        })
        return response?.data
    },
    async searchRoute(start_city, end_city, date, qty) {
        const response = await requestService.get(`/v1/search/${start_city}/${end_city}?date=${date}&date_diff=${qty}`, {
            headers: { 'Accept-Language': store.getters.locale.toLowerCase() }
        })
        return response?.data
    },
    async searchTrip(start_city, end_city, date, qty) {
        const response = await requestService.get(`/v1/search/${start_city}/${end_city}?date=${date}&ticket_count=${qty}&date_diff=1`, {
            headers: { 'Accept-Language': store.getters.locale.toLowerCase() }
        })
        return response?.data
    }
}